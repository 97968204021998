export class URLconstants {}

// URLconstants.BASE_URL = "http://localhost:1337";

URLconstants.BASE_URL = "https://doc-app-uat.capricontechnology.com";

URLconstants.fetchDoctors = () => `${URLconstants.BASE_URL}/api/doctors`;
URLconstants.fetchHospitals = () => `${URLconstants.BASE_URL}/api/hospitals`;
URLconstants.fetchClinics = () => `${URLconstants.BASE_URL}/api/clinics`;
URLconstants.fetchWorkers = () => `${URLconstants.BASE_URL}/api/`;
URLconstants.fetchOrganisations = () =>
  `${URLconstants.BASE_URL}/api/organisations`;
URLconstants.fetchAppoints = () => `${URLconstants.BASE_URL}/api/appoints`;
URLconstants.fetchTranscripts = () =>
  `${URLconstants.BASE_URL}/api/transcripts`;
URLconstants.fetchManualTranscripts = () =>
  `${URLconstants.BASE_URL}/api/manual-transcripts`;

URLconstants.uploadTranscripts = () =>
  `${URLconstants.BASE_URL}/api/upload-manual-transcript`;

URLconstants.uploadSignatures = () =>
  `${URLconstants.BASE_URL}/api/upload-signature`;

URLconstants.saveURL = () => `${URLconstants.BASE_URL}/api/recording/save-url`;
URLconstants.uploadAudio = () => `${URLconstants.BASE_URL}/api/audio-upload`;
URLconstants.transcripter = () => `${URLconstants.BASE_URL}/api/transcripters`;
URLconstants.BASE_URL_FARM = "https://ai-backup.capricontechnology.com";
URLconstants.signManualScripts = () =>
  `${URLconstants.BASE_URL_FARM}/doctor/digital-signature/custom`;
